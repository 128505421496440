import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  Fragment,
} from "react";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import { Link } from "react-router-dom";
import Config from "../Config/Config";
import Dashboard from "../Dashboard/Dashboard";
import Modal from "../Modal/Modal";
import Accordion from "react-bootstrap/Accordion";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Card from "react-bootstrap/Card";
import { validateLabelHelper, validateSNHelper } from "../../utils/helperfuncs";
import { Dropdown, DropdownButton } from "react-bootstrap";
import MobileModal from "../MobileModal/MobileModal";
import { Scrollbars } from "react-custom-scrollbars";
import { KeyboardDatePicker } from "@material-ui/pickers";
import deviceIcon from "../../images/home_device_icon.svg";
import SMIcon from "../../images/home_sm_icon.svg";
import virtDeviceIcon from "../../images/home_virtual_device_icon.svg";
import editIcon from "../../images/home_edit_icon.svg";
import deleteIcon from "../../images/home_delete_icon.svg";
import addSM from "../../images/home_add_sm_icon.svg";
import mobileEditIcon from "../../images/mobile_home_edit_icon.svg";
import mobileDeleteIcon from "../../images/mobile_home_delete_icon.svg";
import mobileAddSM from "../../images/mobile_home_add_sm_icon.svg";
import settingsIcon from "../../images/home_settings_icon.svg";
import dashboardIcon from "../../images/dashboard_icon.svg";
import arrowDown from "../../images/arrow_down.svg";
import verticalDotsIcon from "../../images/mobile_vertical_dots.svg";
import baseURL from "../../baseURL";
import "./ListView.css";

const ListView = (props) => {
  const [selectedConfigDeviceData, setSelectedConfigDeviceData] =
    useState(null);
  const [selectedDelZeeDeviceId, setSelectedDelZeeDeviceId] = useState("");
  const [selectedDelDeviceId, setSelectedDelDeviceId] = useState("");
  const [selectedDelDeviceLabel, setSelectedDelDeviceLabel] = useState(null);
  const [selectedDelDeviceType, setSelectedDelDeviceType] = useState("ZEE");
  const [selectedEditZeeDeviceId, setSelectedEditZeeDeviceId] = useState("");
  const [selectedEditDeviceId, setSelectedEditDeviceId] = useState("");
  const [selectedEditDeviceLabel, setSelectedEditDeviceLabel] = useState("");
  const [selectedEditDeviceInitLabel, setSelectedEditDeviceInitLabel] =
    useState("");
  const [selectedEditDeviceType, setSelectedEditDeviceType] = useState("ZEE");
  const [selectedEditDeviceSN, setSelectedEditDeviceSN] = useState("");
  const [selectedEditDeviceInitSN, setSelectedEditDeviceInitSN] = useState("");
  const [editedDeviceParentId, setEditedDeviceParentId] = useState(null);
  const [initEditedDeviceParentId, setInitEditedDeviceParentId] =
    useState(null);
  const [isModalDel, setIsModalDel] = useState(false);
  const [isModalEd, setIsModalEd] = useState(false);
  const [delError, setDelError] = useState(false);
  const [delLoading, setDelLoading] = useState(false);
  const [delSuccess, setDelSuccess] = useState(false);
  const [editError, setEditError] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [editSuccess, setEditSuccess] = useState(false);
  const [transitionStateConfig, setTransitionStateConfig] = useState(true);
  const [transitionStateDash, setTransitionStateDash] = useState(true);
  // Pick selected SmartZee list item
  const [VDId, setVDId] = useState(null);
  const [accOpenClass, setAccOpenClass] = useState("");
  const [accOpenEvtKey, setAccOpenEvtKey] = useState(null);
  // Add new SM
  const [isModalAddSM, setIsModalAddSM] = useState(false);
  const [addedDeviceLabel, setAddedDeviceLabel] = useState("");
  const [addedDeviceSN, setAddedDeviceSN] = useState("");
  const [fetchError, setFetchError] = useState(false);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [fetchSuccess, setFetchSuccess] = useState(false);
  const [selectedZeeDeviceId, setSelectedZeeDeviceId] = useState("");
  const [validateLabel, setValidateLabel] = useState(false);
  const [validateLabelErrMsg, setValidateLabelErrMsg] = useState("");
  const [validateSN, setValidateSN] = useState(false);
  const [validateSNErrMsg, setValidateSNErrMsg] = useState("");
  // Dashboard Type
  const [dashboardType, setDashboardType] = useState("");
  // Mobile Modal
  const [showMobileModalEd, setShowMobileModalEd] = useState(false);
  const [showMobileModalDel, setShowMobileModalDel] = useState(false);
  const [showMobileModalAddSM, setShowMobileModalAddSM] = useState(false);
  // External Days Mobile modal
  const [showMobileModalDays, setShowMobileModalDays] = useState(false);
  const [repeatDaysModal, setRepeatDaysModal] = useState([]);
  const [repeatDaysRecIndexModal, setRepeatDaysRecIndexModal] = useState(null);
  const [isModalSettings, setIsModalSettings] = useState(false);
  const [fetchRetryError, setFetchRetryError] = useState(false);
  const [fetchRetryLoading, setFetchRetryLoading] = useState(false);
  const [fetchRetrySuccess, setFetchRetrySuccess] = useState(false);
  const [isRetry, setIsRetry] = useState(false);
  const [hasChildren, setHasChildren] = useState([]);
  // Chart Range Selector Mobile modal
  const [showMobileModalRS, setShowMobileModalRS] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [startDateEpoch, setStartDateEpoch] = useState(0);
  const [endDate, setEndDate] = useState(null);
  const [endDateEpoch, setEndDateEpoch] = useState(0);
  const [rsMessage, setRsMessage] = useState("");
  const [rangeSelModalFlag, setRangeSelModalFlag] = useState(false);
  const [closeDashboardWS, setCloseDashboardWS] = useState(false);

  const configRef = useRef();

  const inputRef = useRef();

  useEffect(() => {
    inputRef.current &&
      VDId !== null &&
      !isNaN(VDId) &&
      props.virtualDeviceList &&
      props.virtualDeviceList[0].smartmeters.length !== 0 &&
      inputRef.current.click();
  }, [VDId]);

  useEffect(() => {
    isRetry && configRef.current.getDeviceConfig();
  }, [isRetry]);

  useEffect(() => {
    setVDId(props.virtualDeviceId);
  }, [props.virtualDeviceId]);

  const token =
    localStorage.getItem("token") || sessionStorage.getItem("token");
  const userId =
    localStorage.getItem("userId") || sessionStorage.getItem("userId");

  const editDevice = async () => {
    setEditLoading(true);
    setEditError(false);
    setEditSuccess(false);
    try {
      let url;

      selectedEditDeviceType === "ZEE"
        ? (url = new URL(
            `./api/v1/users/${
              props.userIdAdminOverride || userId
            }/devices/${selectedEditDeviceId}`,
            baseURL
          ))
        : (url = new URL(
            `./api/v1/devices/${selectedEditZeeDeviceId}/smartmeters/${selectedEditDeviceId}`,
            baseURL
          ));

      const obj = {
        name: selectedEditDeviceLabel,
        serial_number: selectedEditDeviceSN,
      };

      if (selectedEditDeviceType === "SM") {
        obj.device = editedDeviceParentId;
      }

      const response = await fetch(url, {
        method: "PATCH",
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(obj),
      });
      let data = await response.json();
      if (response.status === 400) {
        if (
          data.serial_number[0] ===
          `${
            selectedEditDeviceType === "ZEE" ? "device" : "smart meter"
          } with this serial number already exists.`
        ) {
          props.setSubmitErrMsg("Please check submitted serial number");
        }
        setEditLoading(false);
        setEditError(true);
        setTimeout(() => {
          setEditSuccess(false);
          setEditError(false);
          props.setSubmitErrMsg("");
        }, 5000);
      } else {
        setEditLoading(false);
        setEditSuccess(true);
        setSelectedEditDeviceLabel("");
        setSelectedEditDeviceSN("");
        handleCloseModalEd();
        props.getDeviceList();
        selectedEditDeviceType === "SM" &&
          props.getSMetersList(selectedEditZeeDeviceId);
        setTimeout(() => {
          setEditSuccess(false);
          setEditError(false);
        }, 5000);
      }
    } catch (error) {
      setEditLoading(false);
      setEditError(true);
      setTimeout(() => {
        setEditError(false);
      }, 3000);
    }
  };

  const deleteDevice = async () => {
    setDelLoading(true);
    setDelError(false);
    setDelSuccess(false);
    try {
      const head = new Headers();
      head.append("Authorization", "Bearer " + token);
      let url;

      selectedDelDeviceType === "ZEE"
        ? (url = new URL(`./api/v1/devices/${selectedDelDeviceId}`, baseURL))
        : (url = new URL(
            `./api/v1/devices/${selectedDelZeeDeviceId}/smartmeters/${selectedDelDeviceId}`,
            baseURL
          ));

      await fetch(url, {
        method: "DELETE",
        headers: head,
      });
      setDelLoading(false);
      setDelSuccess(true);
      handleCloseModalDel();
      props.getDeviceList();
      selectedDelDeviceType === "SM" &&
        props.getSMetersList(selectedDelZeeDeviceId);
      setTimeout(() => {
        setDelSuccess(false);
        setDelError(false);
      }, 5000);
    } catch (error) {
      setDelLoading(false);
      setDelError(true);
      setTimeout(() => {
        setDelError(false);
      }, 3000);
    }
  };

  const addSMDevice = async () => {
    setFetchLoading(true);
    setFetchError(false);
    setFetchSuccess(false);
    try {
      const url = new URL(
        `./api/v1/devices/${selectedZeeDeviceId}/smartmeters`,
        baseURL
      );

      const response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          device: selectedZeeDeviceId,
          name: addedDeviceLabel,
          serial_number: addedDeviceSN,
        }),
      });

      let data = await response.json();

      if (response.status !== 201) {
        if (
          data.serial_number[0] ===
          "smart meter with this serial number already exists."
        ) {
          props.setSubmitErrMsg("Please check submitted serial number");
        }
        setFetchLoading(false);
        setFetchError(true);
        setTimeout(() => {
          setFetchSuccess(false);
          setFetchError(false);
          props.setSubmitErrMsg("");
        }, 5000);
      } else {
        setFetchLoading(false);
        setFetchSuccess(true);
        props.isMobile
          ? setTimeout(() => {
              props.setIsSuccess(true);
            }, 500)
          : props.setIsSuccess(true);
        setAddedDeviceLabel("");
        setAddedDeviceSN("");
        handleCloseModalAddSM();
        setTimeout(() => {
          setFetchSuccess(false);
          setFetchError(false);
          props.setIsSuccess(false);
        }, 5000);
        // Add newly added SM data to state via frontend
        props.setSmetersListData((prev) => [...prev, data]);
        props.setDeviceListData((prev) =>
          prev.map((obj) =>
            obj.id === selectedZeeDeviceId
              ? { ...obj, smartmeters: [...obj.smartmeters, data.id] }
              : obj
          )
        );
        // props.getDeviceList();
        // props.getSMetersList(selectedZeeDeviceId);
      }
    } catch (error) {
      setFetchLoading(false);
      setFetchError(true);
      setTimeout(() => {
        setFetchError(false);
      }, 3000);
    }
  };

  const handleConfigClick = (e, obj) => {
    props.setIsConfig(true);
    setSelectedConfigDeviceData(obj);
    setTransitionStateConfig((transitionStateConfig) => !transitionStateConfig);
    props.setCloseDeviceWS(true);
    setCloseDashboardWS(true);
  };

  const handleConfigBack = (e) => {
    props.setIsConfig(false);
    setTransitionStateConfig((transitionStateConfig) => !transitionStateConfig);
    setTimeout(() => {
      props.setAnimExit(true);
    }, 1000);
    setAccOpenClass("");
    setAccOpenEvtKey(null);
    props.setOpenZeeId(null);
    props.setCloseDeviceWS(false);
    setCloseDashboardWS(true);
    // Reset virtual device ID to let the expand standalone SMs useEffect kick in
    setVDId(null);
    setTimeout(() => {
      setVDId(props.virtualDeviceId);
    }, 200);
    // Get device list as some user might have deleted some device or SM
    props.getDeviceList();
  };

  const handleDashboardClick = (e, obj) => {
    props.setIsDashboard(true);
    setSelectedConfigDeviceData(obj);
    setTransitionStateDash((transitionStateDash) => !transitionStateDash);
    props.setCloseDeviceWS(true);
    setCloseDashboardWS(false);
  };

  const handleDashboardBack = (e) => {
    props.setIsDashboard(false);
    props.setDashboardType("ZEE");
    setTransitionStateDash((transitionStateDash) => !transitionStateDash);
    setTimeout(() => {
      props.setAnimExit(true);
    }, 1000);
    setAccOpenClass("");
    setAccOpenEvtKey(null);
    props.setOpenZeeId(null);
    setStartDate(null);
    setEndDate(null);
    setStartDateEpoch(0);
    setEndDateEpoch(0);
    props.setCloseDeviceWS(false);
    setCloseDashboardWS(true);
    // Reset virtual device ID to let the expand standalone SMs useEffect kick in
    setVDId(null);
    setTimeout(() => {
      setVDId(props.virtualDeviceId);
    }, 200);
    // Get device list as some user might have deleted some device or SM
    props.getDeviceList();
  };

  const handleCloseModalDel = () => {
    !props.isMobile ? setIsModalDel(!isModalDel) : setShowMobileModalDel(false);
    // Clear button to defaults
    setDelLoading(false);
    setDelSuccess(false);
    setDelError(false);
    props.setSubmitErrMsg("");
  };

  const handleCloseModalEd = () => {
    !props.isMobile ? setIsModalEd(!isModalEd) : setShowMobileModalEd(false);
    setValidateLabel(false);
    setValidateSN(false);
    setValidateLabelErrMsg("");
    setValidateSNErrMsg("");
    setSelectedEditDeviceLabel("");
    setSelectedEditDeviceSN("");
    // Clear button to defaults
    setEditLoading(false);
    setEditSuccess(false);
    setEditError(false);
    props.setSubmitErrMsg("");
  };

  const handleCloseModalAddSM = () => {
    !props.isMobile
      ? setIsModalAddSM(!isModalAddSM)
      : setShowMobileModalAddSM(false);
    setValidateLabel(false);
    setValidateSN(false);
    setValidateLabelErrMsg("");
    setValidateSNErrMsg("");
    setAddedDeviceLabel("");
    setAddedDeviceSN("");
    // Clear button to defaults
    setFetchLoading(false);
    setFetchSuccess(false);
    setFetchError(false);
    props.setSubmitErrMsg("");
  };

  const handleEditLabel = (e) => {
    setSelectedEditDeviceLabel(e.target.value);
    validateLabelHelper(
      e.target.value,
      setValidateLabel,
      setValidateLabelErrMsg
    );
    if (selectedEditDeviceInitLabel === e.target.value) {
      setValidateLabel(false);
      setValidateLabelErrMsg("Label is not changed!");
    }
  };

  const handleEditSN = (e) => {
    setSelectedEditDeviceSN(e.target.value);
    validateSNHelper(e.target.value, setValidateSN, setValidateSNErrMsg);
    if (selectedEditDeviceInitSN === e.target.value) {
      setValidateSN(false);
      setValidateSNErrMsg("Serial number is not changed!");
    }
  };

  const handleAddLabel = (e) => {
    setAddedDeviceLabel(e.target.value);
    validateLabelHelper(
      e.target.value,
      setValidateLabel,
      setValidateLabelErrMsg
    );
  };

  const handleAddSN = (e) => {
    setAddedDeviceSN(e.target.value);
    validateSNHelper(e.target.value, setValidateSN, setValidateSNErrMsg);
  };

  const hasChildrenFunc = (deviceId) => {
    const output = props.deviceListData
      .filter((obj) => obj.id === deviceId)
      .map((obj) => obj.smartmeters);
    setHasChildren(output[0]);
  };

  const renderedMeters = (id) =>
    props.smetersListData
      .filter((obj) => obj.device === id)
      .map((obj, index) => {
        return (
          <li key={obj.id} className='list-group-item-meter'>
            <span className='line-border'></span>
            <span className='device-list-title'>
              <span
                className={
                  index >= 9 && "meter-list-number-wrapper-double-digit"
                }>
                <span className='device-list-number'>
                  <span
                    id='home-sm-device-number'
                    className={index >= 9 && "double-digit"}>
                    {index + 1}
                  </span>
                  <img
                    className='device-icon-img'
                    src={SMIcon}
                    alt='device icon'
                  />
                </span>
              </span>
              <span
                id='home-sm-device-title'
                className='device-list-title-meter'>
                {obj.name}
              </span>
            </span>
            <div className='flex-start'>
              <span
                id='home-sm-device-status-circle'
                className='status-circle'
                style={{
                  backgroundColor: props.SMListStatusArr.some(
                    (arrId) => arrId === obj.id
                  )
                    ? "#5BEB97"
                    : "#84978C",
                }}></span>
              <span
                id='home-sm-device-status-text'
                className='status-text'
                style={{ marginLeft: "10px", fontSize: "14px" }}>
                {props.SMListStatusArr.some((arrId) => arrId === obj.id)
                  ? "ONLINE"
                  : "OFFLINE"}
              </span>
            </div>
            {!props.isMobile && (
              <div className='device-controls'>
                <img
                  id='home-sm-device-del-btn'
                  src={deleteIcon}
                  alt='delete device'
                  onClick={() => {
                    setIsModalDel(true);
                    setSelectedDelZeeDeviceId(obj.device);
                    setSelectedDelDeviceId(obj.id);
                    setSelectedDelDeviceLabel(obj.name);
                    setSelectedDelDeviceType("SM");
                  }}
                />
                <img
                  id='home-sm-device-edit-btn'
                  src={editIcon}
                  alt='edit device'
                  onClick={() => {
                    setIsModalEd(true);
                    setSelectedEditZeeDeviceId(obj.device);
                    setEditedDeviceParentId(obj.device);
                    setInitEditedDeviceParentId(obj.device);
                    setSelectedEditDeviceId(obj.id);
                    setSelectedEditDeviceLabel(obj.name);
                    setSelectedEditDeviceInitLabel(obj.name);
                    setSelectedEditDeviceSN(obj.serial_number);
                    setSelectedEditDeviceInitSN(obj.serial_number);
                    setSelectedEditDeviceType("SM");
                  }}
                />
              </div>
            )}
            <div className='action-btns'>
              <button
                id='home-sm-device-dash-btn'
                className='btn-small btn-home flex-it'>
                <img
                  src={dashboardIcon}
                  alt='sm dashboard'
                  onClick={(e) => {
                    handleDashboardClick(e, obj);
                    props.setDashboardType("SM");
                    setDashboardType("SM");
                  }}
                />
              </button>
              <DropdownButton
                title={
                  props.remotePriceRes &&
                  props.SMListStatusArr.some((arrId) => arrId === obj.id) ? (
                    <span style={{ textTransform: "capitalize" }}>
                      {props.remotePriceRes}
                    </span>
                  ) : (
                    "Set Remote Price"
                  )
                }
                className='btn-small btn-home btn-remote-price flex-it three-dots dots-button sub-btn'
                disabled={props.SMListStatusArr.some(
                  (arrId) => arrId !== obj.id
                )}>
                <Dropdown.Item
                  onClick={() => {
                    props.setRemotePriceId(obj.id);
                    props.setRemotePriceIdOrig(obj.id);
                    props.setRemotePriceVal("1");
                    console.log(obj.id);
                    console.log(props.remotePriceIdOrig);
                  }}>
                  <svg
                    width='26'
                    height='26'
                    viewBox='0 0 26 26'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'>
                    <g clip-path='url(#clip0_322_3)'>
                      <circle
                        cx='13'
                        cy='13'
                        r='12'
                        stroke='#202B33'
                        stroke-width='2'
                      />
                      <path
                        d='M9.73 10C10.688 8.187 12.482 7 14.471 7C15.936 7 17.278 7.632 18.273 8.686C18.457 8.86981 18.7054 8.97469 18.9655 8.97838C19.2256 8.98208 19.4768 8.88431 19.666 8.7058C19.8552 8.5273 19.9674 8.28212 19.9787 8.02227C19.9901 7.76243 19.8998 7.50837 19.727 7.314C18.387 5.894 16.533 5 14.471 5C11.292 5 8.644 7.097 7.545 10H6C5.73478 10 5.48043 10.1054 5.29289 10.2929C5.10536 10.4804 5 10.7348 5 11C5 11.2652 5.10536 11.5196 5.29289 11.7071C5.48043 11.8946 5.73478 12 6 12H7.058C6.98112 12.6645 6.98112 13.3355 7.058 14H6C5.73478 14 5.48043 14.1054 5.29289 14.2929C5.10536 14.4804 5 14.7348 5 15C5 15.2652 5.10536 15.5196 5.29289 15.7071C5.48043 15.8946 5.73478 16 6 16H7.545C8.644 18.903 11.292 21 14.471 21C16.533 21 18.388 20.106 19.727 18.686C19.8998 18.4916 19.9901 18.2376 19.9787 17.9777C19.9674 17.7179 19.8552 17.4727 19.666 17.2942C19.4768 17.1157 19.2256 17.0179 18.9655 17.0216C18.7054 17.0253 18.457 17.1302 18.273 17.314C17.278 18.368 15.936 19 14.47 19C12.481 19 10.687 17.813 9.729 16H14C14.2652 16 14.5196 15.8946 14.7071 15.7071C14.8946 15.5196 15 15.2652 15 15C15 14.7348 14.8946 14.4804 14.7071 14.2929C14.5196 14.1054 14.2652 14 14 14H9.075C8.97642 13.337 8.97642 12.663 9.075 12H14C14.2652 12 14.5196 11.8946 14.7071 11.7071C14.8946 11.5196 15 11.2652 15 11C15 10.7348 14.8946 10.4804 14.7071 10.2929C14.5196 10.1054 14.2652 10 14 10H9.73Z'
                        fill='#202B33'
                      />
                    </g>
                    <defs>
                      <clipPath id='clip0_322_3'>
                        <rect width='26' height='26' fill='white' />
                      </clipPath>
                    </defs>
                  </svg>

                  <span style={{ paddingLeft: "10px" }}>
                    Set Remote Price ON
                  </span>
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    props.setRemotePriceId(obj.id);
                    props.setRemotePriceIdOrig(obj.id);
                    props.setRemotePriceVal("0");
                  }}>
                  <svg
                    width='26'
                    height='26'
                    viewBox='0 0 26 26'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'>
                    <g clip-path='url(#clip0_322_7)'>
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M15.489 7C12.43 7 10 9.213 10 12.5C10 15.887 12.527 18.5 15.489 18.5C16.232 18.5 16.94 18.339 17.587 18.046C17.707 17.9886 17.8373 17.9557 17.9702 17.9492C18.1031 17.9427 18.2359 17.9629 18.3609 18.0084C18.4859 18.0539 18.6006 18.124 18.6982 18.2144C18.7958 18.3048 18.8744 18.4138 18.9294 18.5349C18.9843 18.6561 19.0146 18.787 19.0183 18.92C19.022 19.053 18.9992 19.1854 18.9511 19.3095C18.903 19.4335 18.8306 19.5467 18.7382 19.6425C18.6458 19.7382 18.5353 19.8145 18.413 19.867C17.4944 20.2833 16.4976 20.4991 15.489 20.5C11.283 20.5 8 16.846 8 12.5C8 8.055 11.38 5 15.489 5C16.7257 5.00067 17.917 5.392 19.063 6.174C19.1754 6.2462 19.2723 6.34021 19.3478 6.45047C19.4233 6.56073 19.4759 6.68501 19.5026 6.81595C19.5293 6.9469 19.5294 7.08186 19.503 7.21287C19.4767 7.34388 19.4243 7.46826 19.349 7.57869C19.2738 7.68911 19.1771 7.78333 19.0648 7.85578C18.9525 7.92822 18.8269 7.97743 18.6952 8.00048C18.5636 8.02354 18.4287 8.01997 18.2984 7.99001C18.1682 7.96004 18.0453 7.90427 17.937 7.826C17.079 7.242 16.274 7 15.489 7Z'
                        fill='#202B33'
                      />
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M6 11C6 10.7348 6.10536 10.4804 6.29289 10.2929C6.48043 10.1054 6.73478 10 7 10H16C16.2652 10 16.5196 10.1054 16.7071 10.2929C16.8946 10.4804 17 10.7348 17 11C17 11.2652 16.8946 11.5196 16.7071 11.7071C16.5196 11.8946 16.2652 12 16 12H7C6.73478 12 6.48043 11.8946 6.29289 11.7071C6.10536 11.5196 6 11.2652 6 11ZM6 14.5C6 14.2348 6.10536 13.9804 6.29289 13.7929C6.48043 13.6054 6.73478 13.5 7 13.5H15C15.2652 13.5 15.5196 13.6054 15.7071 13.7929C15.8946 13.9804 16 14.2348 16 14.5C16 14.7652 15.8946 15.0196 15.7071 15.2071C15.5196 15.3946 15.2652 15.5 15 15.5H7C6.73478 15.5 6.48043 15.3946 6.29289 15.2071C6.10536 15.0196 6 14.7652 6 14.5Z'
                        fill='#202B33'
                      />
                      <path
                        d='M4.29301 5.70692C4.11085 5.51832 4.01006 5.26571 4.01234 5.00352C4.01461 4.74132 4.11978 4.49051 4.30519 4.3051C4.4906 4.11969 4.74141 4.01452 5.00361 4.01224C5.26581 4.00997 5.51841 4.11076 5.70701 4.29292L21.707 20.2929C21.8892 20.4815 21.99 20.7341 21.9877 20.9963C21.9854 21.2585 21.8802 21.5093 21.6948 21.6947C21.5094 21.8801 21.2586 21.9853 20.9964 21.9876C20.7342 21.9899 20.4816 21.8891 20.293 21.7069L4.29301 5.70692Z'
                        fill='#202B33'
                      />
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M13 24C19.075 24 24 19.075 24 13C24 6.925 19.075 2 13 2C6.925 2 2 6.925 2 13C2 19.075 6.925 24 13 24ZM13 26C20.18 26 26 20.18 26 13C26 5.82 20.18 0 13 0C5.82 0 0 5.82 0 13C0 20.18 5.82 26 13 26Z'
                        fill='#202B33'
                      />
                    </g>
                    <defs>
                      <clipPath id='clip0_322_7'>
                        <rect width='26' height='26' fill='white' />
                      </clipPath>
                    </defs>
                  </svg>

                  <span style={{ paddingLeft: "10px" }}>
                    Set Remote Price OFF
                  </span>
                </Dropdown.Item>
              </DropdownButton>
              {/* <button
                className='btn-small btn-home btn-remote-price flex-it'
                onClick={() => props.setRemotePriceId(obj.id)}>
                Set Remote Price
              </button> */}
            </div>
            {props.isMobile && (
              <div className='device-controls-mobile'>
                <DropdownButton
                  id='home-sm-device-controls-dot-mobile'
                  title={<img src={verticalDotsIcon} alt='dots' />}
                  className='three-dots dots-button'>
                  <Dropdown.Item
                    id='home-sm-device-edit-device-btn-mobile'
                    onClick={() => {
                      setShowMobileModalEd(true);
                      setSelectedEditZeeDeviceId(obj.device);
                      setEditedDeviceParentId(obj.device);
                      setInitEditedDeviceParentId(obj.device);
                      setSelectedEditDeviceId(obj.id);
                      setSelectedEditDeviceLabel(obj.name);
                      setSelectedEditDeviceInitLabel(obj.name);
                      setSelectedEditDeviceSN(obj.serial_number);
                      setSelectedEditDeviceInitSN(obj.serial_number);
                      setSelectedEditDeviceType("SM");
                    }}>
                    <img src={mobileEditIcon} alt='edit device' />
                    <span style={{ paddingLeft: "10px" }}>Edit Device</span>
                  </Dropdown.Item>
                  <Dropdown.Item
                    id='home-sm-device-del-device-btn-mobile'
                    onClick={() => {
                      setShowMobileModalDel(true);
                      setSelectedDelZeeDeviceId(obj.device);
                      setSelectedDelDeviceId(obj.id);
                      setSelectedDelDeviceLabel(obj.name);
                      setSelectedDelDeviceType("SM");
                    }}>
                    <img src={mobileDeleteIcon} alt='delete device' />
                    <span style={{ paddingLeft: "10px" }}>Delete Device</span>
                  </Dropdown.Item>
                </DropdownButton>
              </div>
            )}
          </li>
        );
      });

  const CustomToggle = ({ children, eventKey, callback, id, obj }) => {
    const decoratedOnClick = useAccordionButton(eventKey, () => {
      callback && callback(eventKey);
      if (eventKey === accOpenEvtKey) {
        setAccOpenClass("fwd");
        setTimeout(() => {
          setAccOpenClass("fwd-fin");
          setAccOpenEvtKey(null);
          props.setOpenZeeId(null);
        }, 500);
      } else {
        setAccOpenEvtKey(eventKey);
        props.setOpenZeeId(eventKey);
        setAccOpenClass("rev");
        setTimeout(() => {
          setAccOpenClass("rev-fin");
          props.getSMetersList(id);
        }, 500);
      }
    });

    return (
      <button
        ref={inputRef}
        type='button'
        id='home-acc-dropdown-btn'
        className={`acc-dropdown-btn ${
          obj.smartmeters.length === 0 && "no-children"
        }`}
        onClick={decoratedOnClick}>
        {children}
      </button>
    );
  };

  const renderedList =
    props.deviceListData &&
    props.deviceListData.map((obj, index) => {
      return (
        <Card key={obj.id}>
          <Card.Header className='list-group-item'>
            <span
              className={`device-list-number-wrapper ${
                index >= 9 && "device-list-number-wrapper-double-digit"
              }`}>
              <span className='device-list-number'>
                <span
                  id='home-zee-device-number'
                  className={index >= 9 && "double-digit"}>
                  {index + 1}
                </span>
                <img
                  className='device-icon-img'
                  src={deviceIcon}
                  alt='device icon'
                />
              </span>
            </span>
            <span
              id='home-zee-device-title'
              className='device-list-title device-list-title-zee'>
              {obj.name}
            </span>
            <div className='device-status flex-start'>
              <span
                id='home-zee-device-status-circle'
                className='status-circle'
                style={{
                  backgroundColor: props.deviceListStatusArr.some(
                    (arrId) => arrId === obj.id
                  )
                    ? "#5BEB97"
                    : "#84978C",
                }}></span>
              <span
                id='home-zee-device-status-text'
                className='status-text'
                style={{ marginLeft: "10px", fontSize: "14px" }}>
                {props.deviceListStatusArr.some((arrId) => arrId === obj.id)
                  ? "ONLINE"
                  : "OFFLINE"}
              </span>
            </div>
            {!props.isMobile && (
              <div className='device-controls'>
                <img
                  id='home-zee-device-del-btn'
                  src={deleteIcon}
                  alt='delete device'
                  onClick={() => {
                    setIsModalDel(true);
                    setSelectedDelDeviceId(obj.id);
                    setSelectedDelDeviceLabel(obj.name);
                    setSelectedDelDeviceType("ZEE");
                    hasChildrenFunc(obj.id);
                  }}
                />
                <img
                  id='home-zee-device-edit-btn'
                  src={editIcon}
                  alt='edit device'
                  onClick={() => {
                    setIsModalEd(true);
                    setSelectedEditDeviceId(obj.id);
                    setSelectedEditDeviceLabel(obj.name);
                    setSelectedEditDeviceInitLabel(obj.name);
                    setSelectedEditDeviceSN(obj.serial_number);
                    setSelectedEditDeviceInitSN(obj.serial_number);
                    setSelectedEditDeviceType("ZEE");
                  }}
                />
                <img
                  id='home-zee-device-add-sm-btn'
                  src={addSM}
                  alt='add new sm'
                  onClick={() => {
                    setIsModalAddSM(true);
                    setSelectedZeeDeviceId(obj.id);
                  }}
                />
              </div>
            )}
            <div className='action-btns'>
              <button
                id='home-zee-device-settings-btn'
                className='btn-small btn-home flex-it'>
                <img
                  src={settingsIcon}
                  alt='device settings'
                  onClick={(e) => handleConfigClick(e, obj)}
                />
              </button>
              <button
                id='home-zee-device-dash-btn'
                className='btn-small btn-home flex-it'>
                <img
                  src={dashboardIcon}
                  alt='smartzee dashboard'
                  onClick={(e) => {
                    handleDashboardClick(e, obj);
                    props.setDashboardType("ZEE");
                    setDashboardType("ZEE");
                  }}
                />
              </button>
            </div>
            {props.isMobile && (
              <div className='device-controls-mobile'>
                <DropdownButton
                  id='home-zee-device-controls-dot-mobile'
                  title={<img src={verticalDotsIcon} alt='dots' />}
                  className='three-dots dots-button'>
                  <Dropdown.Item
                    id='home-zee-device-add-sm-btn-mobile'
                    onClick={() => {
                      setShowMobileModalAddSM(true);
                      setSelectedZeeDeviceId(obj.id);
                    }}>
                    <img src={mobileAddSM} alt='add new sm' />
                    <span style={{ paddingLeft: "10px" }}>Add SM Device</span>
                  </Dropdown.Item>
                  <Dropdown.Item
                    id='home-zee-device-edit-device-btn-mobile'
                    onClick={() => {
                      setShowMobileModalEd(true);
                      setSelectedEditDeviceId(obj.id);
                      setSelectedEditDeviceLabel(obj.name);
                      setSelectedEditDeviceInitLabel(obj.name);
                      setSelectedEditDeviceSN(obj.serial_number);
                      setSelectedEditDeviceInitSN(obj.serial_number);
                      setSelectedEditDeviceType("ZEE");
                    }}>
                    <img src={mobileEditIcon} alt='edit device' />
                    <span style={{ paddingLeft: "10px" }}>Edit Device</span>
                  </Dropdown.Item>
                  <Dropdown.Item
                    id='home-zee-device-del-device-btn-mobile'
                    onClick={() => {
                      setShowMobileModalDel(true);
                      setSelectedDelDeviceId(obj.id);
                      setSelectedDelDeviceLabel(obj.name);
                      setSelectedDelDeviceType("ZEE");
                      hasChildrenFunc(obj.id);
                    }}>
                    <img src={mobileDeleteIcon} alt='delete device' />
                    <span style={{ paddingLeft: "10px" }}>Delete Device</span>
                  </Dropdown.Item>
                </DropdownButton>
              </div>
            )}
            <CustomToggle eventKey={obj.id} id={obj.id} obj={obj}>
              <img
                className={accOpenEvtKey == obj.id && accOpenClass}
                src={arrowDown}
                alt='arrow'
              />
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey={obj.id}>
            <ul
              className='meters-list'
              style={{
                display: obj.smartmeters.length > 0 ? "block" : "none",
              }}>
              {props.SMLoading && props.SMLoadedId !== obj.id ? (
                <li className='list-group-item-meter'>
                  <span className='line-border'></span>
                  <div className='lds-ring'>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </li>
              ) : props.SMLoadingErr ? (
                <li className='list-group-item-meter list-group-item-err'>
                  <span className='line-border'></span>
                  <p style={{ fontSize: "12px", textAlign: "left" }}>
                    Failed to get SM list. Please close drawer and reopen it to
                    try again.
                  </p>
                </li>
              ) : (
                renderedMeters(obj.id)
              )}
            </ul>
          </Accordion.Collapse>
        </Card>
      );
    });

  const renderedVirtList =
    props.virtualDeviceList &&
    props.virtualDeviceList.map((obj, index) => {
      return (
        <Card key={obj.id}>
          <Card.Header className='list-group-item list-group-item-virt'>
            <span
              className={`device-list-number-wrapper ${
                index >= 9 && "device-list-number-wrapper-double-digit"
              }`}>
              <span className='device-list-number'>
                <img
                  className='device-icon-img virt-device-icon-img'
                  src={virtDeviceIcon}
                  alt='virtual device icon'
                />
              </span>
            </span>
            <span className='device-list-title device-list-title-zee device-list-title-standalone'>
              Standalone Smart Meters
            </span>
            <div className='device-status flex-start'></div>
            {!props.isMobile && <div className='device-controls'></div>}
            <div className='action-btns'>
              <DropdownButton
                title={
                  props.remotePriceResAll ? (
                    <span style={{ textTransform: "capitalize" }}>
                      {props.remotePriceResAll}
                    </span>
                  ) : (
                    "Set Remote Price All"
                  )
                }
                className='btn-small btn-home btn-remote-price flex-it three-dots dots-button sub-btn'>
                <Dropdown.Item
                  onClick={() => {
                    // props.setRemotePriceId(obj.id);
                    // props.setRemotePriceVal("1");
                  }}>
                  <svg
                    width='26'
                    height='26'
                    viewBox='0 0 26 26'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'>
                    <g clip-path='url(#clip0_322_3)'>
                      <circle
                        cx='13'
                        cy='13'
                        r='12'
                        stroke='#202B33'
                        stroke-width='2'
                      />
                      <path
                        d='M9.73 10C10.688 8.187 12.482 7 14.471 7C15.936 7 17.278 7.632 18.273 8.686C18.457 8.86981 18.7054 8.97469 18.9655 8.97838C19.2256 8.98208 19.4768 8.88431 19.666 8.7058C19.8552 8.5273 19.9674 8.28212 19.9787 8.02227C19.9901 7.76243 19.8998 7.50837 19.727 7.314C18.387 5.894 16.533 5 14.471 5C11.292 5 8.644 7.097 7.545 10H6C5.73478 10 5.48043 10.1054 5.29289 10.2929C5.10536 10.4804 5 10.7348 5 11C5 11.2652 5.10536 11.5196 5.29289 11.7071C5.48043 11.8946 5.73478 12 6 12H7.058C6.98112 12.6645 6.98112 13.3355 7.058 14H6C5.73478 14 5.48043 14.1054 5.29289 14.2929C5.10536 14.4804 5 14.7348 5 15C5 15.2652 5.10536 15.5196 5.29289 15.7071C5.48043 15.8946 5.73478 16 6 16H7.545C8.644 18.903 11.292 21 14.471 21C16.533 21 18.388 20.106 19.727 18.686C19.8998 18.4916 19.9901 18.2376 19.9787 17.9777C19.9674 17.7179 19.8552 17.4727 19.666 17.2942C19.4768 17.1157 19.2256 17.0179 18.9655 17.0216C18.7054 17.0253 18.457 17.1302 18.273 17.314C17.278 18.368 15.936 19 14.47 19C12.481 19 10.687 17.813 9.729 16H14C14.2652 16 14.5196 15.8946 14.7071 15.7071C14.8946 15.5196 15 15.2652 15 15C15 14.7348 14.8946 14.4804 14.7071 14.2929C14.5196 14.1054 14.2652 14 14 14H9.075C8.97642 13.337 8.97642 12.663 9.075 12H14C14.2652 12 14.5196 11.8946 14.7071 11.7071C14.8946 11.5196 15 11.2652 15 11C15 10.7348 14.8946 10.4804 14.7071 10.2929C14.5196 10.1054 14.2652 10 14 10H9.73Z'
                        fill='#202B33'
                      />
                    </g>
                    <defs>
                      <clipPath id='clip0_322_3'>
                        <rect width='26' height='26' fill='white' />
                      </clipPath>
                    </defs>
                  </svg>

                  <span style={{ paddingLeft: "10px" }}>
                    Set Remote Price All ON
                  </span>
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    // props.setRemotePriceId(obj.id);
                    // props.setRemotePriceVal("0");
                  }}>
                  <svg
                    width='26'
                    height='26'
                    viewBox='0 0 26 26'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'>
                    <g clip-path='url(#clip0_322_7)'>
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M15.489 7C12.43 7 10 9.213 10 12.5C10 15.887 12.527 18.5 15.489 18.5C16.232 18.5 16.94 18.339 17.587 18.046C17.707 17.9886 17.8373 17.9557 17.9702 17.9492C18.1031 17.9427 18.2359 17.9629 18.3609 18.0084C18.4859 18.0539 18.6006 18.124 18.6982 18.2144C18.7958 18.3048 18.8744 18.4138 18.9294 18.5349C18.9843 18.6561 19.0146 18.787 19.0183 18.92C19.022 19.053 18.9992 19.1854 18.9511 19.3095C18.903 19.4335 18.8306 19.5467 18.7382 19.6425C18.6458 19.7382 18.5353 19.8145 18.413 19.867C17.4944 20.2833 16.4976 20.4991 15.489 20.5C11.283 20.5 8 16.846 8 12.5C8 8.055 11.38 5 15.489 5C16.7257 5.00067 17.917 5.392 19.063 6.174C19.1754 6.2462 19.2723 6.34021 19.3478 6.45047C19.4233 6.56073 19.4759 6.68501 19.5026 6.81595C19.5293 6.9469 19.5294 7.08186 19.503 7.21287C19.4767 7.34388 19.4243 7.46826 19.349 7.57869C19.2738 7.68911 19.1771 7.78333 19.0648 7.85578C18.9525 7.92822 18.8269 7.97743 18.6952 8.00048C18.5636 8.02354 18.4287 8.01997 18.2984 7.99001C18.1682 7.96004 18.0453 7.90427 17.937 7.826C17.079 7.242 16.274 7 15.489 7Z'
                        fill='#202B33'
                      />
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M6 11C6 10.7348 6.10536 10.4804 6.29289 10.2929C6.48043 10.1054 6.73478 10 7 10H16C16.2652 10 16.5196 10.1054 16.7071 10.2929C16.8946 10.4804 17 10.7348 17 11C17 11.2652 16.8946 11.5196 16.7071 11.7071C16.5196 11.8946 16.2652 12 16 12H7C6.73478 12 6.48043 11.8946 6.29289 11.7071C6.10536 11.5196 6 11.2652 6 11ZM6 14.5C6 14.2348 6.10536 13.9804 6.29289 13.7929C6.48043 13.6054 6.73478 13.5 7 13.5H15C15.2652 13.5 15.5196 13.6054 15.7071 13.7929C15.8946 13.9804 16 14.2348 16 14.5C16 14.7652 15.8946 15.0196 15.7071 15.2071C15.5196 15.3946 15.2652 15.5 15 15.5H7C6.73478 15.5 6.48043 15.3946 6.29289 15.2071C6.10536 15.0196 6 14.7652 6 14.5Z'
                        fill='#202B33'
                      />
                      <path
                        d='M4.29301 5.70692C4.11085 5.51832 4.01006 5.26571 4.01234 5.00352C4.01461 4.74132 4.11978 4.49051 4.30519 4.3051C4.4906 4.11969 4.74141 4.01452 5.00361 4.01224C5.26581 4.00997 5.51841 4.11076 5.70701 4.29292L21.707 20.2929C21.8892 20.4815 21.99 20.7341 21.9877 20.9963C21.9854 21.2585 21.8802 21.5093 21.6948 21.6947C21.5094 21.8801 21.2586 21.9853 20.9964 21.9876C20.7342 21.9899 20.4816 21.8891 20.293 21.7069L4.29301 5.70692Z'
                        fill='#202B33'
                      />
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M13 24C19.075 24 24 19.075 24 13C24 6.925 19.075 2 13 2C6.925 2 2 6.925 2 13C2 19.075 6.925 24 13 24ZM13 26C20.18 26 26 20.18 26 13C26 5.82 20.18 0 13 0C5.82 0 0 5.82 0 13C0 20.18 5.82 26 13 26Z'
                        fill='#202B33'
                      />
                    </g>
                    <defs>
                      <clipPath id='clip0_322_7'>
                        <rect width='26' height='26' fill='white' />
                      </clipPath>
                    </defs>
                  </svg>

                  <span style={{ paddingLeft: "10px" }}>
                    Set Remote Price All OFF
                  </span>
                </Dropdown.Item>
              </DropdownButton>
            </div>
            <CustomToggle eventKey={VDId} id={obj.id} obj={obj}>
              <img
                className={accOpenEvtKey == obj.id && accOpenClass}
                src={arrowDown}
                alt='arrow'
              />
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey={VDId}>
            <ul
              className='meters-list'
              style={{
                display: obj.smartmeters.length > 0 ? "block" : "none",
              }}>
              {props.SMLoading && props.SMLoadedId !== obj.id ? (
                <li className='list-group-item-meter'>
                  <span className='line-border'></span>
                  <div className='lds-ring'>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </li>
              ) : props.SMLoadingErr ? (
                <li className='list-group-item-meter list-group-item-err'>
                  <span className='line-border'></span>
                  <p style={{ fontSize: "12px", textAlign: "left" }}>
                    Failed to get SM list. Please close drawer and reopen it to
                    try again.
                  </p>
                </li>
              ) : (
                renderedMeters(obj.id)
              )}
            </ul>
          </Accordion.Collapse>
        </Card>
      );
    });

  const JSXContentDel = (
    <Fragment>
      <h6 style={{ textAlign: "left" }}>
        Are you sure you want to delete {selectedDelDeviceLabel}?
      </h6>
      <h6
        id='home-del-device-modal-message'
        style={{
          textAlign: "left",
          marginTop: "1rem",
          color: "red",
          fontWeight: "bold",
          // display:
          //   (selectedDelDeviceType === "SM" || hasChildren.length === 0) &&
          //   "none",
        }}>
        {selectedDelDeviceType === "SM"
          ? "CAUTION!: All smart meter device data will be destroyed forever as well!"
          : selectedDelDeviceType === "ZEE" && hasChildren.length === 0
          ? "CAUTION!: All SmartZee device data will be destroyed forever as well!"
          : "CAUTION!: All smart meters defined under this SmartZee device will be deleted as well! All SmartZee and child smart meters data will be destroyed forever as well!!"}
      </h6>
    </Fragment>
  );

  const JSXContentEd = (
    <Fragment>
      {selectedEditDeviceType === "SM" && (
        <div className='columns '>
          <div className='column flex-start'>
            <label className='label'>Device Parent</label>
          </div>
          <div className='column'>
            <div className='select is-fullwidth'>
              <select
                id='home-edit-device-modal-device-parent-sel'
                className='input load-control-input transparent-input'
                value={editedDeviceParentId}
                onInput={(e) =>
                  setEditedDeviceParentId(parseInt(e.target.value))
                }>
                {props.deviceListData.map((obj, key) => {
                  return (
                    <option key={key} value={obj.id}>
                      {obj.name}
                    </option>
                  );
                })}
                <option value={props.virtualDeviceId}>None</option>
              </select>
            </div>
          </div>
          <div className='column'></div>
        </div>
      )}
      <div className='columns '>
        <div className='column flex-start'>
          <label className='label'>Device Label</label>
        </div>
        <div className='column'>
          <input
            id='home-edit-device-modal-device-label-input'
            className='input'
            type='text'
            maxLength={20}
            onChange={handleEditLabel}
            value={selectedEditDeviceLabel}
          />
        </div>
        <div className='column'>
          <div className='input-err-msg-wrapper'>
            <p
              id='home-edit-device-modal-device-label-err-msg'
              className='email-err-msg m-0'
              style={{
                fontSize: "11px",
                color: "red",
                textAlign: "left",
                visibility: !validateLabel ? "visible" : "hidden",
              }}>
              {validateLabelErrMsg}
            </p>
          </div>
        </div>
      </div>
      <div className='columns '>
        <div className='column flex-start'>
          <label className='label'>Serial Number</label>
        </div>
        <div className='column'>
          <input
            id='home-edit-device-modal-device-serial-input'
            className='input'
            type='text'
            maxLength={12}
            onChange={handleEditSN}
            value={selectedEditDeviceSN}
          />
        </div>
        <div className='column'>
          <div className='input-err-msg-wrapper'>
            <p
              id='home-edit-device-modal-device-serial-err-msg'
              className='email-err-msg m-0'
              style={{
                fontSize: "11px",
                color: "red",
                textAlign: "left",
                visibility: !validateSN ? "visible" : "hidden",
              }}>
              {validateSNErrMsg}
            </p>
          </div>
        </div>
      </div>
    </Fragment>
  );

  const JSXContentAddSM = (
    <Fragment>
      <div className='columns '>
        <div className='column flex-start'>
          <label className='label'>Device Label</label>
        </div>
        <div className='column'>
          <input
            id='home-add-sm-device-modal-device-label-input'
            className='input'
            type='text'
            maxLength={20}
            onChange={handleAddLabel}
            value={addedDeviceLabel}
          />
        </div>
        <div className='column'>
          <div className='input-err-msg-wrapper'>
            <p
              id='home-add-sm-device-modal-device-label-input-err-msg'
              className='email-err-msg m-0'
              style={{
                fontSize: "11px",
                color: "red",
                textAlign: "left",
                visibility: !validateLabel ? "visible" : "hidden",
              }}>
              {validateLabelErrMsg}
            </p>
          </div>
        </div>
      </div>
      <div className='columns '>
        <div className='column flex-start'>
          <label className='label'>Serial Number</label>
        </div>
        <div className='column'>
          <input
            id='home-add-sm-device-modal-device-serial-input'
            className='input'
            type='text'
            maxLength={12}
            onChange={handleAddSN}
            value={addedDeviceSN}
          />
        </div>
        <div className='column'>
          <div className='input-err-msg-wrapper'>
            <p
              id='home-add-sm-device-modal-device-serial-err-msg'
              className='email-err-msg m-0'
              style={{
                fontSize: "11px",
                color: "red",
                textAlign: "left",
                visibility: !validateSN ? "visible" : "hidden",
              }}>
              {validateSNErrMsg}
            </p>
          </div>
        </div>
      </div>
    </Fragment>
  );

  // External Days Mobile modal
  let daysArr = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

  // External Days Mobile modal
  const handleSingleCheck = (e) => {
    let name = e.target.name;
    let newArr;
    if (!repeatDaysModal.includes(name)) {
      newArr = [...repeatDaysModal];
      newArr.push(name);
      setRepeatDaysModal(newArr);
    } else {
      newArr = [...repeatDaysModal];
      newArr.splice(repeatDaysModal.indexOf(name), 1);
      setRepeatDaysModal(newArr);
    }
    console.log(newArr);
  };

  // External Days Mobile modal
  const handleCloseModalDays = () => {
    setRepeatDaysModal([]);
    setShowMobileModalDays(false);
  };

  // External Days Mobile modal
  const handleSaveDays = () => {
    setShowMobileModalDays(false);
  };

  // External Days Mobile modal
  const returnRealDayNames = (day) => {
    switch (day) {
      case "Mon":
        return "Monday";
      case "Tue":
        return "Tuesday";
      case "Wed":
        return "Wednesday";
      case "Thu":
        return "Thursday";
      case "Fri":
        return "Friday";
      case "Sat":
        return "Saturday";
      case "Sun":
        return "Sunday";
    }
  };

  // External Days Mobile modal
  const JSXContentRepeatDays = (
    <Fragment>
      <div className='time-days-mobile'>
        {daysArr.map((day, index) => (
          <div key={index} className='time-day-mobile'>
            <span>{returnRealDayNames(day)}</span>
            <input
              id={`config-time-repeat-days-modal-mobile-${day}`}
              className='checkbox'
              type='checkbox'
              name={day}
              checked={repeatDaysModal.includes(day)}
              onChange={handleSingleCheck}
              disabled={false}
            />
          </div>
        ))}
      </div>
    </Fragment>
  );

  // Chart Range Selector Mobile modal
  const convertDateToEpoch = (date) => {
    return parseInt(date / 1000);
  };

  const handleChangeStartDate = (val) => {
    setStartDate(val);
    setStartDateEpoch(convertDateToEpoch(val));
  };

  const handleChangeEndDate = (val) => {
    setEndDate(val);
    setEndDateEpoch(convertDateToEpoch(val));
  };

  const handleCloseModalRS = () => {
    setShowMobileModalRS(false);
  };

  const handleRangeSelectSubmit = () => {
    console.log(startDateEpoch, endDateEpoch);
    if (startDateEpoch >= endDateEpoch) {
      console.log("End date must be ahead of start date!");
      setRsMessage("Invalid date range!");
      setTimeout(() => {
        setRsMessage("");
      }, 1500);
    } else if (isNaN(startDateEpoch) || isNaN(endDateEpoch)) {
      console.log("Invalid date range!");
      setRsMessage("Invalid date range!");
      setTimeout(() => {
        setRsMessage("");
      }, 1500);
    } else {
      setRangeSelModalFlag(true);
      setRsMessage("");
      handleCloseModalRS();
    }
  };

  const JSXContentChartRS = (
    <Fragment>
      <div className='columns '>
        <div className='column flex-start'>
          <label className='label'>From</label>
        </div>
        <div className='column'>
          <KeyboardDatePicker
            format='DD/MM/yyyy'
            onChange={handleChangeStartDate}
            value={startDate}
            InputProps={{
              id: "dash-charts-fs-controls-mobile-custom-sel-date-from-input",
            }}
            KeyboardButtonProps={{
              id: "dash-charts-fs-controls-mobile-custom-sel-date-from-picker",
            }}
          />
        </div>
      </div>
      <div className='columns '>
        <div className='column flex-start'>
          <label className='label'>To</label>
        </div>
        <div className='column'>
          <KeyboardDatePicker
            format='DD/MM/yyyy'
            onChange={handleChangeEndDate}
            value={endDate}
            InputProps={{
              id: "dash-charts-fs-controls-mobile-custom-sel-date-to-input",
            }}
            KeyboardButtonProps={{
              id: "dash-charts-fs-controls-mobile-custom-sel-date-to-picker",
            }}
          />
        </div>
        <div className='column'>
          <div className='input-err-msg-wrapper'>
            <p
              id='dash-charts-fs-controls-mobile-custom-sel-date-err-msg'
              className='email-err-msg m-0'
              style={{
                fontSize: "11px",
                color: "red",
                textAlign: "left",
                visibility: rsMessage !== "" ? "visible" : "hidden",
              }}>
              {rsMessage}
            </p>
          </div>
        </div>
      </div>
    </Fragment>
  );

  const handleCloseModalSettings = () => {
    setIsModalSettings(!isModalSettings);
    handleConfigBack();
  };

  const handleCloseModalSettingsAfterSuccess = () => {
    setIsModalSettings(false);
  };

  const runGetDeviceConfigFromConfig = () => {
    setIsRetry(true);
    // This will call a useEffect to run getDeviceData func from child <Config />
  };

  return (
    <Fragment>
      <div className='device-wrapper'>
        <SwitchTransition mode='out-in'>
          <CSSTransition
            key={
              props.isConfig
                ? transitionStateConfig
                : props.isDashboard
                ? transitionStateDash
                : null
            }
            addEndListener={(node, done) => {
              node.addEventListener("transitionend", done, false);
            }}
            classNames='slide'>
            {transitionStateConfig && transitionStateDash ? (
              !props.isMobile ? (
                <Scrollbars
                  autoHeight
                  id='device-list-scrollbar'
                  style={{
                    maxHeight: props.isMobile
                      ? "initial"
                      : "calc(100vh - 300px)",
                  }}
                  renderThumbVertical={({ style, ...props }) => (
                    <div
                      {...props}
                      style={{
                        ...style,
                        backgroundColor: "gray",
                        borderRadius: "30px",
                      }}
                    />
                  )}>
                  <Accordion id='device-list' defaultActiveKey={accOpenEvtKey}>
                    {renderedList}
                    {props.virtualDeviceList &&
                      props.virtualDeviceList.length !== 0 &&
                      props.virtualDeviceList[0].smartmeters.length !== 0 &&
                      renderedVirtList}
                  </Accordion>
                </Scrollbars>
              ) : (
                <Accordion id='device-list' defaultActiveKey={accOpenEvtKey}>
                  {renderedList}
                  {props.virtualDeviceList &&
                    props.virtualDeviceList.length !== 0 &&
                    props.virtualDeviceList[0].smartmeters.length !== 0 &&
                    renderedVirtList}
                </Accordion>
              )
            ) : transitionStateConfig && !transitionStateDash ? (
              <Dashboard
                dashboardType={dashboardType}
                selectedConfigDeviceData={selectedConfigDeviceData}
                handleDashboardBack={handleDashboardBack}
                setAnimExit={props.setAnimExit}
                isMobile={props.isMobile}
                isTablet={props.isTablet}
                isDashboard={props.isDashboard}
                getDeviceList={props.getDeviceList}
                setShowMobileModalRS={setShowMobileModalRS}
                startDateEpoch={startDateEpoch}
                endDateEpoch={endDateEpoch}
                rangeSelModalFlag={rangeSelModalFlag}
                setRangeSelModalFlag={setRangeSelModalFlag}
                closeDashboardWS={closeDashboardWS}
              />
            ) : (
              <Config
                ref={configRef}
                selectedConfigDeviceData={selectedConfigDeviceData}
                handleConfigBack={handleConfigBack}
                setAnimExit={props.setAnimExit}
                showMobileModal={showMobileModalDays}
                setShowMobileModal={setShowMobileModalDays}
                repeatDaysModal={repeatDaysModal}
                setRepeatDaysModal={setRepeatDaysModal}
                repeatDaysRecIndexModal={repeatDaysRecIndexModal}
                setRepeatDaysRecIndexModal={setRepeatDaysRecIndexModal}
                isMobile={props.isMobile}
                setIsModalSettings={setIsModalSettings}
                isRetry={isRetry}
                setIsRetry={setIsRetry}
                setFetchRetryLoading={setFetchRetryLoading}
                setFetchRetrySuccess={setFetchRetrySuccess}
                setFetchRetryError={setFetchRetryError}
                handleCloseModalSettingsAfterSuccess={
                  handleCloseModalSettingsAfterSuccess
                }
              />
            )}
          </CSSTransition>
        </SwitchTransition>
      </div>
      {/* Delete Modal Desktop */}
      <Modal
        title={`Delete ${selectedDelDeviceType} Device`}
        content={JSXContentDel}
        actionName='Delete'
        isModal={isModalDel}
        handleCloseModal={handleCloseModalDel}
        actionFn={deleteDevice}
        loading={delLoading}
        error={delError}
        success={delSuccess}
        actionBtnId='home-del-device-modal-action-btn'
        cancelBtnId='home-del-device-modal-cancel-btn'
      />
      {/* Delete Modal Mobile */}
      <MobileModal
        title={`Delete ${selectedDelDeviceType} Device`}
        content={JSXContentDel}
        actionName='Delete'
        showMobileModal={showMobileModalDel}
        setShowMobileModal={setShowMobileModalDel}
        handleCloseModal={handleCloseModalDel}
        actionFn={deleteDevice}
        loading={delLoading}
        error={delError}
        success={delSuccess}
        actionBtnId='home-del-device-modal-action-btn-mobile'
        cancelBtnId='home-del-device-modal-cancel-btn-mobile'
      />
      {/* Edit Modal Desktop */}
      <Modal
        title={`Edit ${selectedEditDeviceType} Device`}
        content={JSXContentEd}
        isModal={isModalEd}
        handleCloseModal={handleCloseModalEd}
        actionFn={editDevice}
        loading={editLoading}
        error={editError}
        success={editSuccess}
        disabled={
          !validateLabel &&
          !validateSN &&
          editedDeviceParentId === initEditedDeviceParentId
        }
        errMsg={props.submitErrMsg}
        actionBtnId='home-edit-device-modal-action-btn'
        cancelBtnId='home-edit-device-modal-cancel-btn'
      />
      {/* Edit Modal Mobile */}
      <MobileModal
        title={`Edit ${selectedEditDeviceType} Device`}
        content={JSXContentEd}
        showMobileModal={showMobileModalEd}
        setShowMobileModal={setShowMobileModalEd}
        handleCloseModal={handleCloseModalEd}
        actionFn={editDevice}
        loading={editLoading}
        error={editError}
        success={editSuccess}
        disabled={
          !validateLabel &&
          !validateSN &&
          editedDeviceParentId === initEditedDeviceParentId
        }
        errMsg={props.submitErrMsg}
        actionBtnId='home-edit-device-modal-action-btn-mobile'
        cancelBtnId='home-edit-device-modal-cancel-btn-mobile'
      />
      {/* Add SM Modal Desktop */}
      <Modal
        title='Add SM Device'
        content={JSXContentAddSM}
        isModal={isModalAddSM}
        handleCloseModal={handleCloseModalAddSM}
        actionFn={addSMDevice}
        loading={fetchLoading}
        error={fetchError}
        success={fetchSuccess}
        disabled={!validateLabel || !validateSN}
        errMsg={props.submitErrMsg}
        actionBtnId='home-add-sm-device-modal-action-btn'
        cancelBtnId='home-add-sm-device-modal-cancel-btn'
      />
      {/* Add SM Modal Mobile */}
      <MobileModal
        title='Add SM Device'
        content={JSXContentAddSM}
        showMobileModal={showMobileModalAddSM}
        setShowMobileModal={setShowMobileModalAddSM}
        handleCloseModal={handleCloseModalAddSM}
        actionFn={addSMDevice}
        loading={fetchLoading}
        error={fetchError}
        success={fetchSuccess}
        disabled={!validateLabel || !validateSN}
        errMsg={props.submitErrMsg}
        actionBtnId='home-add-sm-device-modal-action-btn-mobile'
        cancelBtnId='home-add-sm-device-modal-cancel-btn-mobile'
      />
      {/* External Days Mobile modal */}
      <MobileModal
        showMobileModal={showMobileModalDays}
        setShowMobileModal={setShowMobileModalDays}
        title='Select Days'
        content={JSXContentRepeatDays}
        handleCloseModal={handleCloseModalDays}
        actionFn={handleSaveDays}
        disabled={repeatDaysModal.length === 0}
        actionBtnId='config-time-repeat-days-modal-action-btn-mobile'
        cancelBtnId='config-time-repeat-days-modal-cancel-btn-mobile'
      />
      {/* Failed to load settings modal */}
      <Modal
        title='Error!'
        content='Failed to load settings. Please try again.'
        actionName='Retry'
        isModal={isModalSettings}
        handleCloseModal={handleCloseModalSettings}
        actionFn={runGetDeviceConfigFromConfig}
        loading={fetchRetryLoading}
        error={fetchRetryError}
        success={fetchRetrySuccess}
        actionBtnId='config-failed-to-load-settings-modal-action-btn-mobile'
        cancelBtnId='config-failed-to-load-settings-modal-cancel-btn-mobile'
      />
      {/* Charts Range Selector Mobile modal */}
      <MobileModal
        showMobileModal={showMobileModalRS}
        setShowMobileModal={setShowMobileModalRS}
        title='Select Date Range'
        content={JSXContentChartRS}
        actionName='Submit'
        handleCloseModal={handleCloseModalRS}
        actionFn={handleRangeSelectSubmit}
        actionBtnId='dash-charts-fs-controls-mobile-action-btn'
        cancelBtnId='dash-charts-fs-controls-mobile-cancel-btn'
      />
      {/* User manual PDF link */}
      {!props.isDashboard && !props.isConfig && (
        <div id='user-manual-pdf-link'>
          <div id='user-manual-pdf-link-content'>
            <svg
              width='18'
              height='22'
              viewBox='0 0 18 22'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'>
              <g clip-path='url(#clip0_301_2)'>
                <path
                  d='M9.9728 4C10.5088 4 10.7768 4.3648 10.7768 4.7828C10.7768 5.3048 10.3112 5.7876 9.7052 5.7876C9.1976 5.7876 8.9016 5.4876 8.9156 4.9916C8.9156 4.5744 9.268 4 9.9728 4ZM8.3236 12C7.9004 12 7.5904 11.7392 7.8864 10.5904L8.372 8.5536C8.4564 8.228 8.4704 8.0972 8.372 8.0972C8.2452 8.0972 7.6964 8.322 7.3712 8.544L7.16 8.192C8.1888 7.3176 9.3724 6.8052 9.8804 6.8052C10.3032 6.8052 10.3736 7.3144 10.1624 8.0972L9.606 10.238C9.5076 10.616 9.5496 10.7464 9.6484 10.7464C9.7752 10.7464 10.1912 10.5896 10.6 10.2636L10.84 10.5892C9.8392 11.608 8.746 12 8.3236 12Z'
                  fill='#202B33'
                />
                <path
                  fill-rule='evenodd'
                  clip-rule='evenodd'
                  d='M6.94501 0.25H11.055C12.423 0.25 13.525 0.25 14.392 0.367C15.292 0.487 16.05 0.747 16.652 1.348C17.254 1.95 17.512 2.708 17.634 3.608C17.75 4.475 17.75 5.578 17.75 6.945V15.055C17.75 16.422 17.75 17.525 17.634 18.392C17.513 19.292 17.254 20.05 16.652 20.652C16.05 21.254 15.292 21.512 14.392 21.634C13.525 21.75 12.422 21.75 11.055 21.75H6.94501C5.57801 21.75 4.47501 21.75 3.60801 21.634C2.70801 21.512 1.95001 21.254 1.34801 20.652C0.747009 20.05 0.488009 19.292 0.367009 18.392C0.326731 18.0789 0.299374 17.7643 0.285009 17.449C0.244797 17.3221 0.239272 17.1868 0.269009 17.057C0.252525 16.3898 0.24619 15.7224 0.250009 15.055V6.945C0.250009 5.578 0.250009 4.475 0.367009 3.608C0.487009 2.708 0.747009 1.95 1.34901 1.348C1.95001 0.746 2.70901 0.488 3.60901 0.367C4.47501 0.25 5.57801 0.25 6.94501 0.25ZM1.77701 17.25C1.79201 17.603 1.81601 17.914 1.85301 18.192C1.95201 18.926 2.13301 19.314 2.40901 19.591C2.68601 19.868 3.07501 20.048 3.80901 20.147C4.56401 20.248 5.56501 20.25 7.00001 20.25H11C12.436 20.25 13.437 20.248 14.192 20.147C14.926 20.048 15.314 19.867 15.592 19.591C15.788 19.395 15.935 19.142 16.04 18.75H5.00001C4.8011 18.75 4.61033 18.671 4.46968 18.5303C4.32903 18.3897 4.25001 18.1989 4.25001 18C4.25001 17.8011 4.32903 17.6103 4.46968 17.4697C4.61033 17.329 4.8011 17.25 5.00001 17.25H16.223C16.242 16.819 16.248 16.325 16.249 15.75H4.89801C3.92001 15.75 3.57801 15.756 3.31601 15.827C2.96319 15.9213 2.63861 16.1 2.3703 16.3477C2.102 16.5955 1.89803 16.9048 1.77601 17.249L1.77701 17.25ZM16.25 14.25H4.78201C3.96401 14.25 3.40601 14.25 2.92701 14.378C2.50578 14.4908 2.10748 14.6763 1.75001 14.926V7C1.75001 5.565 1.75201 4.563 1.85301 3.808C1.95201 3.074 2.13301 2.686 2.40901 2.409C2.68601 2.132 3.07501 1.952 3.80901 1.853C4.56401 1.752 5.56501 1.75 7.00001 1.75H11C12.436 1.75 13.437 1.752 14.192 1.853C14.926 1.952 15.314 2.133 15.592 2.409C15.868 2.686 16.048 3.074 16.147 3.809C16.249 4.563 16.25 5.565 16.25 7V14.25Z'
                  fill='#202B33'
                />
              </g>
              <defs>
                <clipPath id='clip0_301_2'>
                  <rect width='18' height='22' fill='white' />
                </clipPath>
              </defs>
            </svg>
            <Link
              to={{
                pathname:
                  "https://dev.smartzee.com/static_files/SmartZee%20User%20Manual-Draft%20130623.pdf",
              }}
              target='_blank'>
              <span>Download User Manual</span>
            </Link>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default ListView;
